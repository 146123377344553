export const mockTokenData = [
  
  {
    date: "2024-01-02",
    token_count: [
      {
        service_name: "Backend",
        moduleName: "Open AI",
        token_count: 220,
      },
     
      {
        service_name: "RBAC",
        moduleName: "Open AI",
        token_count: 90,
      },
      {
        service_name: "PlaceHolder API",
        moduleName: "Open AI",
        token_count: 85,
      }
    ],
  },

  {
    date: "2024-01-04",
    token_count: [

      {
        service_name: "Auth",
        moduleName: "Open AI",
        token_count: 110,
      },
      {
        service_name: "RBAC",
        moduleName: "Open AI",
        token_count: 65,
      },
      {
        service_name: "PlaceHolder API",
        moduleName: "Open AI",
        token_count: 95,
      }
    ],
  }
];


 export  const mockDocStats = [
    { date: "2024-01-01", status: "SUCCEEDED", processed: 300 },
    { date: "2024-01-02", status: "FAILED", processed: 50 },
    { date: "2024-01-02", status: "Processing", processed: 54 },
];